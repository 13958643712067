import React from "react"
import { Link } from "gatsby"
// import PostItems from "./PostItems"
import PostItem from "./PostItem"
import { FeaturedProductsStyles } from "./FeaturesStyles"
import useLatestBlogPost from "../../hooks/use-latest-blog-post"
import Button from "../Button/Button"

const LatestPosts = ({ title, introduction }) => {
  const latestBlogPost = useLatestBlogPost()
  return (
    <FeaturedProductsStyles className="section">
      {title || introduction ? (
        <div className="container container__tight">
          <div className="intro__area">
            {title && (
              <h2>
                {title}
                <span>.</span>
              </h2>
            )}
            {introduction && <p>{introduction}</p>}
          </div>
        </div>
      ) : null}

      <div className="container container__tight container__scroll">
      {/* <PostItems> */}
        {latestBlogPost.map((node, index) => {
          return <PostItem key={index} node={node} />
        })}
      {/* </PostItems> */}
      </div>
      <div className="container container__tight learn__more">
        <Button text="全ての投稿" as={Link} to="/posts" />
      </div>
    </FeaturedProductsStyles>
  )
}

export default LatestPosts
